.app-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.app-body {
  display: flex;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.main-content {
  width: calc(100% - #{$sidebar-width});
  height: 100%;
  background: linear-gradient(-45deg, #292e49, #3a6073, #292e49, #292e49);
  overflow: auto;
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  margin: 0 0 0 0;
  // margin-top: $navbar-height;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
