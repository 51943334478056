.sidebar {
  top: $navbar-height;
  width: $sidebar-width;
  height: calc(100%);
  overflow: hidden;
  background: linear-gradient(-45deg, #2b6c6f, #346661, #377377);
  color: #fff;

  .nav-link {
    color: #fff;
  }
  .active {
    color: #fff;
    background: linear-gradient(-45deg, #2c7d8e, #3f9f8f, #397f83);
  }
}
