.contact-us {
  width: 100%;
  max-width: 40rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  // background: linear-gradient(-45deg, #2b6c6f, #346661, #377377);
  color: #fff;
  padding: 2rem 0.5rem 0.5rem 0.5rem;
  margin: 0 0 0 0;

  &-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    // background: linear-gradient(-45deg, #2b6c6f, #346661, #377377);
    color: #fff;
    padding: 2rem 0.5rem 0.5rem 0rem;
    margin: 0 0 2rem 0;

    &-item {
      color: #fff;
    }
  }
}
